.landing-page {
  text-align: center;
}

.hero-section {
  padding: 50px 20px;
  background-color: var(--background-color);
}

.hero-section h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.hero-section p {
  font-size: 18px;
  margin-bottom: 30px;
}

.cta-button {
  background-color: var(--primary-color);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  text-decoration: none;
}

.cta-button:hover {
  background-color: var(--accent-color);
}

.features-section {
  padding: 50px 20px;
}

.features-section h2 {
  font-size: 30px;
  margin-bottom: 40px;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.feature {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.feature h3 {
  font-size: 20px;
  margin-bottom: 15px;
}

.feature p {
  font-size: 16px;
}

@media (max-width: 768px) {
  .features-grid {
    grid-template-columns: 1fr;
  }
}
