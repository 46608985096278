.app-header { 
  padding: 10px 30px;
  background-color: var(--primary-color); /* Adjust the primary color accordingly */
  color: white;
}

.header-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: left;
  align-items: center;
} 

.app-title {
  flex-grow: 1;
  color: #fff;
  font-size: 1.5em; /* Adjust size as needed */
  margin: 0; /* Ensures there is no default margin */
  flex-shrink: 0; /* Prevents title from shrinking */
}

.header-nav {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.header-link, .header-button {
  color: white;
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 4px;
  transition: background-color 0.3s;
  align-items: center;
}

.header-button {
  background-color: #f04f47; /* Button color */
  border:none;
  align-items: center;
}

.header-button:hover {
  background-color: #c03c37; /* Button hover color */
}

.profile-dropdown {
  position: relative;
}

.team-invites-dropdown {
  color: #004b93;
  padding: 10px;
  display: block;
  text-align: left;
}

.team-invite {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px; /* Adds space between invites */
  flex-direction: column; /* This stacks children vertically */
  align-items: center; /* This centers children horizontally */
}

.team-invite span{
  margin-right: 10px; /* Adds space between the group name and the buttons */
  margin-bottom: 10px;
}

.team-invite div {
  display: flex; /* Also make the div a flex container */
  justify-content: center; /* Centers buttons horizontally */
  gap: 10px; /* Space between buttons */
}

.team-invite button {
  padding: 5px 10px;
  margin-left: 4px; /* Space between buttons */
}

.profile-dropdown-content, .team-invites-dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 4px;
  padding: 12px 16px;
}

.profile-dropdown:hover .profile-dropdown-content,
.team-invites-dropdown:hover .team-invites-dropdown-content {
  display: block;
}

.profile-dropdown-content a, .team-invites-dropdown-content button {
  color: #004b93;
  padding: 10px;
  display: block;
  text-align: left;
}

.profile-dropdown-content a:hover, .team-invites-dropdown-content button:hover {
  background-color: #e1e1e1;
}

.error-message {
  color: #f44336;
  margin-top: 10px;
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
  .app-header {
    flex-direction: column;
    text-align: center;
    padding: 10px 15px; /* Adjust padding for smaller screens */
  }

  .app-title, .header-nav {
    margin: 5px 0; /* Reduce margin for smaller screens */
  }

  .profile-dropdown-content, .team-invites-dropdown-content {
    position: absolute;
    box-shadow: none;
    width: auto;
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .profile-dropdown:hover .profile-dropdown-content,
  .team-invites-dropdown:hover .team-invites-dropdown-content {
    display: flex;
    flex-direction: column;
  }

  .header-link, .header-button {
    width: 100%;
    padding: 10px; /* Adjust padding for smaller screens */
    display: flex; /* Added to display items in a row */
  }
}
