/* Dashboard.css */
.dashboard-container {
  text-align: center;
  font-family: Arial, sans-serif;
}

.dashboard-content {
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 1000px;
  margin: 20px auto;
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab {
  padding: 10px 20px;
  border: 1px solid #ccc;
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 0 10px;
  border-radius: 5px;
}

.tab.active {
  background-color: var(--primary-color);
  color: white;
  border-color: var(--primary-color);
}

.tab:hover {
  background-color: var(--accent-color);
  border-color: var(--accent-color);
  color: white;
}

.tab-content {
  display: flex;
  width: 100%;
}

.sidebar {
  flex: 0 0 200px;
  border-right: 1px solid #ccc;
  padding: 10px;
}

.menu-item {
  display: block;
  color: var(--primary-color);
  padding: 10px;
  border: none;
  background-color: transparent;
  text-align: left;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.menu-item.active{
  background-color: var(--primary-color);
  color: white;
}

.menu-item:hover {
  background-color: var(--accent-color);
  color: white;
}

.profile-details, .group-details {
  flex: 1;
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-left: 20px;
}

.profile-content, .group-content {
  display: flex;
  width: 100%;
}

.group-details {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px; /* Space below the group details section */
}

.group-details h2 {
  color: #333;
  margin-bottom: 20px;
}

.group-form {
  display: flex;
  align-items: center;
}

.group-form input[type="text"] {
  flex-grow: 1;
  margin-right: 10px; /* Ensure there is some space between the input and the button */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.group-form button {
  padding: 10px 20px;
  background-color: #5cb85c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: auto; /* This will push the button to the right */
}

.group-form button:hover {
  background-color: #4cae4c;
}

.group-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 20px;
}

.group-card {
  width: calc(33.333% - 10px); /* Adjust the width of the card */
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.group-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.group-card img.group-image {
  width: 100%;
  height: 150px; /* Adjust the height of the image */
  object-fit: cover;
}

.group-card .group-name {
  padding: 10px;
  background-color: #f8f9fa;
  text-align: center;
  font-weight: bold;
  color: #333;
}

fieldset {
  border: 1px solid #eaeaea;
  padding: 15px;
  border-radius: 5px;
}

legend {
  font-size: 18px;
  font-weight: bold;
  color: #0056b3;
  margin-bottom: 10px; /* Add space below the legend */
}

.form-group {
  margin-bottom: 15px; /* Increase spacing for better readability */
}

.form-group label {
  font-size: 16px;
  color: #333;
  margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group input[type="date"],
.form-group input[type="time"],
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8; /* Slightly off-white background for the input fields */
}

.form-group input[type="text"]:focus,
.form-group input[type="date"]:focus,
.form-group input[type="time"]:focus,
.form-group select:focus {
  border-color: #0056b3;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 0 2px rgba(0, 86, 179, 0.25);
}

.options-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px; /* Padding inside the container for the options */
  border: 1px solid #eaeaea;
  border-radius: 4px;
  background-color: #f9f9f9; /* Slightly different background to distinguish the area */
  margin-bottom: 15px;
}

.option {
  padding: 5px 15px;
  border-radius: 15px;
  background-color: #e9ecef;
  color: #495057;
  border: none;
  cursor: pointer;
}

.option.selected {
  background-color: #0056b3;
  color: #ffffff;
}

.option:not(.selected):hover {
  background-color: #dae0e5;
}


@media (max-width: 768px) {
  .dashboard-content {
    width: 100%; /* Full width on smaller screens */
    padding: 10px;
    box-sizing: border-box;
  }

  .tabs {
    flex-direction: row; /* Stack tabs vertically */
  }

  .tab {
    width: 100%; /* Full width for buttons */
    margin-bottom: 10px; /* Add space between tabs */
  }

  .sidebar {
    width: 100%; /* Full width for the sidebar */
    margin-bottom: 20px; /* Add space below the sidebar */
    padding: 15px;
    box-sizing: border-box;
    border-right:transparent;
    border-bottom: 1px solid #ccc;
  }

  .menu-item {
    justify-content: center; /* Center menu items */
    padding: 10px; /* Larger padding for clickable area */
    font-size: 16px; /* Increase font size for readability */
  }

  .profile-content,
  .group-content {
    flex-direction: column; /* Stack sections vertically */
  }

  .group-details, .profile-details {
    width: 100%;
    margin: 0;
    padding: 15px;
    box-sizing: border-box;
  }
  .group-form input[type="text"] {
    width: calc(100% - 20px); /* Adjust the width to leave space for padding and borders */
  }
  
  .form-group {
    width: 100%; /* Full width for form groups */
  }


  .form-group label,
  .form-group input,
  .form-group select,
  .form-group button {
    font-size: 14px; /* Adjust font size for form elements */
  }

  .form-group input[type="text"],
  .form-group input[type="date"],
  .form-group input[type="time"],
  .form-group select {
    width: 100%; /* Full width for input and select elements */
    margin: 5px 0; /* Adjust spacing */
  }

  .form-group select[multiple] {
    padding: 8px; /* Adjust padding for multi-select */
  }

  .selected-option {
    font-size: 12px; /* Adjust font size for selected options */
  }

  button[type="submit"] {
    padding: 12px 24px; /* Adjust padding for submit button */
  }

  /* If any custom buttons or option tags are used */
  .option {
    font-size: 14px; /* Adjust font size */
    padding: 8px; /* Adjust padding */
  }

  /* Adjust the form fields and button in group details section */
  .group-details form {
    flex-direction: row; /* Stack input and button vertically */
  }

  .group-details form input {
    flex-grow: 1;
    margin-right: 10px; /* Ensures space between the input and the button */
  }

  .group-details form button {
    white-space: nowrap;
    padding: 10px 15px; /* Adjust padding as needed */
  }

  .group-cards {
    justify-content: center;
  }

  .group-card {
    width: 50%; /* Full width on small screens */
    margin-bottom: 10px; /* Add space between cards */
  }

  .group-card .group-image {
    height: auto; /* Adjust height to maintain aspect ratio */
  }
}

@media (max-width: 480px) {
  .group-card {
    width: 100%; /* Full width on very small screens */
  }
}
