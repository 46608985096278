.popup-form-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* Slightly darker for better overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Higher z-index to ensure it's above other elements */
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
  }
  
  .popup-form-backdrop.active {
    opacity: 1;
    visibility: visible;
  }
  
  .popup-form {
    background: white;
    padding: 25px;
    border-radius: 15px;
    width: 500px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    transition: transform 0.3s; /* Smooth transition for popup */
    transform: scale(0.9); /* Start slightly scaled down */
  }
  
  .popup-form.active {
    transform: scale(1); /* Scale up to normal size when active */
  }
  
  .popup-form-header {
    justify-content: space-between;
    margin-bottom: 30px;
  }
  
  .popup-form-header button {
    padding: 10px 15px;
    border: none;
    background: none;
    cursor: pointer;
    transition: color 0.2s, border-color 0.2s;
    color: black;
  }

  .popup-form-header .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    color: var(--primary-color); /* Use the variable you set for primary color */
  }
  
  .popup-form-header {
    padding-right: 40px; /* Make sure this is enough space for the close button */
  }
  
  .popup-form-header button:hover {
    color: var(--primary-color); /* Interaction indication */
  }
  
  .popup-form-header button.active {
    border-bottom: 3px solid var(--primary-color); /* Thicker border for active state */
    font-weight: bold; /* Make the active tab bolder */
  }
  
  .close-btn:before {
    content: "\00D7"; /* Unicode multiplication sign (×) */
    color: var(--secondary-color); /* Secondary color for the close button */
    font-weight: bold;
  }
  
  .close-btn:hover {
    transform: rotate(90deg); /* Rotate the close button when hovering */
  }

  .change-role-container {
    max-width: 400px;
    margin: 0 auto;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .change-role-container h2 {
    color: #333;
    margin-bottom: 20px;
  }
  
  .change-role-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
  
  .change-role-form label {
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
  }
  
  .change-role-form select {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
  }
  
  .change-role-form button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: var(--primary-color);
    color: white;
    cursor: pointer;
    transition: background-color 0.2s;
  }

  .injury-card {
    border: 1px solid #ddd;
    padding: 20px;
    margin: 20px 0;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .injury-card h3 {
    margin-top: 0;
    color: var(--primary-color);
  }
  
  .injury-card button {
    padding: 8px 16px;
    margin-right: 10px;
    border: none;
    border-radius: 4px;
    background-color: var(--secondary-color);
    color: white;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .injury-card button:hover {
    background-color: darken(var(--secondary-color), 10%);
  }
  
  .view-past-injuries-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: var(--accent-color);
    color: white;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .view-past-injuries-btn:hover {
    background-color: darken(var(--accent-color), 10%);
  }
  
  .button-container {
    display: flex;
    justify-content: space-between;
  }
  
  .injury-button,
  .past-injuries-button {
    flex: 1; /* Makes buttons split the available space */
    padding: 10px; /* Adjust padding as needed for height */
    margin: 5px; /* Adds space between buttons */
    /* Additional button styling */
  }
  
  /* If you want to ensure that the buttons are the same height regardless of content: */
  .injury-button {
    height: 50px; /* Adjust as needed */
  }
  
  .past-injuries-button {
    width: 100%; /* Full width for the single button */
    height: 50px; /* Should be the same as the other buttons */
  }

  .rehabilitation-popup-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1500; /* Make sure this value is higher than the z-index of the standard popup */
  }
  
  .rehabilitation-popup {
    background: white;
    padding: 25px;
    border-radius: 15px;
    width: 500px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    z-index: 1501; /* This should be higher than the backdrop */
  }

  
  .rehabilitation-popup .popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc; /* Optional, adds a line under the header */
  }

  .rehabilitation-popup .close-btn {
    margin-left: auto; /* This will push the button to the far right */
    padding: 0 10px;
    font-size: 24px; /* Adjust as needed */
    line-height: 1;
    background: none;
    border: none;
    cursor: pointer;
  
  }

  .notes-container {
    display: flex;
    gap: 20px; /* Space between trainer and player notes */
    margin-bottom: 20px; /* Space below the entire notes container */
  }
  
  .note-card {
    padding: 2px;
    margin-bottom: 2px;
  }

  .note-card p {
    font-size: 1rem; /* Adjust the font size to your preference */
    line-height: 1.6; /* This gives a nice readable line height */
    color: #333; /* Darker text for better readability */
    background-color: #f7f7f7; /* A light background to highlight the note */
    border-left: 3px solid #004b93; /* A colored border on the left for some design flair */
    padding: 10px 15px; /* Padding inside the notes */
    margin: 10px 0; /* Space between each note */
    border-radius: 5px; /* Rounded corners for the notes */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* A subtle shadow for depth */
    white-space: pre-wrap; /* This will ensure that any formatting like new lines in the note text is preserved */
  }
  
  .trainer-notes, .player-notes {
    flex: 1; /* Each takes up equal width */
  }
  
  .add-note-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .add-note-container input {
    flex-grow: 1;
    margin-right: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .add-note-container button {
    padding: 10px 20px;
    background-color: var(--accent-color);
    color: white;
    border: none;
    border-radius: 5px;
  }
  

  /* Apply responsive styles for smaller screens */
  @media (max-width: 600px) {
    .popup-form {
      width: 90%;
      margin: 0 5%;
      box-sizing: border-box; /* Include padding and border in the element's total width and height */
    }
    .notes-container {
      flex-direction: column; /* Stack the notes vertically on small screens */
    }
  
    .add-note-container input,
    .add-note-container button {
      width: 100%; /* Full width on small screens */
    }
  }
  