.submit-injury {
	max-width: 100%;
	/* Allow the form to take full width */
	padding: 15px;
	margin: 0 auto;
	/* Center the form in the viewport */
}

.submit-injury h2 {
	font-size: 20px;
	/* Reduce font size for the title */
}

.submit-injury form {
	width: 100%;
}

.submit-injury {
	max-width: 600px;
	margin: 20px auto;
	padding: 20px;
	border-radius: 10px;
	background-color: #ffffff;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	border: 1px solid #ddd;
}

.submit-injury h2 {
	font-size: 24px;
	color: #0056b3;
	/* Use a consistent primary color */
	margin-bottom: 30px;
	/* More space below the title for visual separation */
}

.submit-injury form {
	display: grid;
	grid-gap: 20px;
	grid-template-columns: 1fr;
	/* Makes sure all fields align */
}

.submit-injury button[type="submit"] {
	background-color: #28a745;
	color: white;
	padding: 10px 15px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	font-size: 16px;
	/* Slightly larger font for the button */
	margin-top: 20px;
	/* Space above the button */
}

.submit-injury [type="submit"]:hover {
	background-color: #218838;
}