.injury-details-container {
    max-width: 600px;
    margin: 40px auto;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.injury-details-container h2 {
    text-align: center;
    margin-bottom: 30px;
    color: #0056b3;
}

section {
    margin-bottom: 25px;
    padding-bottom: 15px;
    border-bottom: 1px solid #eaeaea;
}

section:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
}

section h3 {
    color: #0056b3;
    font-size: 1.2em;
    margin-bottom: 15px;
}

.detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.detail strong {
    font-weight: 600;
    color: #333;
    margin-right: 10px;
}

.detail span {
    color: #555;
}

@media (max-width: 768px) {
    .injury-details-container {
        margin: 20px;
        padding: 15px;
    }

    .injury-details-container h2 {
        font-size: 1.4em;
        margin-bottom: 20px;
    }

    section h3 {
        font-size: 1em;
    }

    .detail {
        display: block;
        margin-bottom: 15px;
    }

    .detail strong {
        display: block;
        margin-bottom: 5px;
    }

    .detail span {
        display: block;
    }
}
