/* D_ViewHistory.css */

.latest-injury-container,
.active-injuries-container,
.historical-injuries-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #e1e1e1;
}

.latest-injury-container h2,
.active-injuries-container h2,
.historical-injuries-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.injury-card {
  padding: 10px;
  margin-bottom: 10px;
  background: #f9f9f9;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.injury-card:hover {
  background-color: #e9e9e9;
}

.injury-card p {
  margin: 0;
  line-height: 1.5;
}

.latest-injury-card {
  display: block;
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  padding: 0;
}

.latest-injury-section {
  padding: 10px;
  background: #f1f1f1;
  border-radius: 4px;
  margin-bottom: 10px;
}

.latest-injury-subtitle {
  font-weight: bold;
  margin-bottom: 5px;
}

.latest-injury-section p {
  margin: 5px 0;
}

.latest-injury-section span {
  font-weight: normal;
}

/* Add media queries for responsive design if needed */
