:root {
  --primary-color: #1976D2; /* A strong, dependable blue for primary actions */
  --secondary-color: #90CAF9; /* A lighter blue for secondary elements and to provide a contrast */
  --accent-color: #FFB74D; /* An orange accent for calls to action and highlights */
  --background-color: #FFFFFF; /* A clean, white background for clarity and readability */
  --text-color: #263238; /* A dark gray that is softer than black for main text */
  --error-color: #D32F2F; /* A strong red for error messages or alerts */
  --success-color: #388E3C; /* A green that can indicate success or completion */
  --info-color: #0288D1; /* Blue for informational messages */
}


body {
  background-color: var(--background-color);
  color: var(--text-color);
}

.primary-button {
  background-color: var(--primary-color);
  color: white;
}

.secondary-button {
  background-color: var(--secondary-color);
  color: white;
}

.accent-button {
  background-color: var(--accent-color);
  color: white;
}
