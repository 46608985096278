.register-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  transition: box-shadow 0.3s ease-in-out;
}

.register-form {
  display: grid;
  grid-template-columns: 1fr; /* One column layout */
  grid-gap: 20px;
}

.register-form .form-group,
.register-form .form-section {
  display: flex;
  flex-direction: column;
}

.register-form .form-group label,
.register-form .form-section label {
  font-weight: 600;
  margin-bottom: 5px;
}

.register-form .form-group input,
.register-form .form-section input,
.register-form .form-section select {
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px; /* Spacing after each input */
}

.register-form .form-group input:focus,
.register-form .form-section input:focus,
.register-form .form-section select:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 2px rgba(40, 167, 69, 0.25);
  outline: none;
}

.register-button {
  background-color: #28a745;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px; /* Slightly larger font size for the button */
}

.register-button:hover {
  background-color: #218838; /* A darker green for hover state */
}

.error-message {
  color: #d32f2f;
  background-color: #ffebee;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  border: 1px solid #f44336;
  text-align: center;
}

@media (max-width: 768px) {
  /* Adjustments for smaller screens */
  .register-container {
    padding: 20px;
    margin: 20px auto;
  }

  .register-form {
    grid-gap: 15px;
  }

  .register-form .form-group input,
  .register-form .form-section input,
  .register-form .form-section select {
    padding: 10px; /* Smaller padding on small screens */
  }

  .register-button {
    padding: 10px 15px;
  }
}
