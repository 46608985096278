/* ManageProfile.css */
.manage-profile-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
  }
  
  .manage-profile-container h2 {
    font-size: 24px;
    color: #0056b3; /* Consistent primary color */
    margin-bottom: 30px; /* Space below the title for visual separation */
  }
  
  .manage-profile-form {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr; /* Ensures all fields align */
  }
  
  .manage-profile-form input,
  .manage-profile-form select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px; /* Slightly larger font for readability */
  }
  
  .manage-profile-form input:focus,
  .manage-profile-form select:focus {
    border-color: #80bdff;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 0 2px rgba(0, 123, 255, 0.25);
    outline: none;
  }
  
  .manage-profile-form button[type="submit"] {
    background-color: #28a745;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px; /* Match the font size of the inputs */
    margin-top: 20px; /* Space above the button */
  }
  
  .manage-profile-form button[type="submit"]:hover {
    background-color: #218838; /* Darker shade for hover state */
  }