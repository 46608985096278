.group-details-container {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

h2, h3, h4 {
  color: #333;
}

section {
  margin-bottom: 20px;
}

h4 {
  margin-bottom: 10px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 5px;
}

.group-invite-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.group-invite-container input[type="email"] {
  flex-grow: 1;
  margin-right: 15px;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.group-invite-container button {
  white-space: nowrap;
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #218838;
}

.error-message {
  color: #d32f2f;
  margin-top: 10px;
}

.members-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 10px;
}

.member-card {
  position: relative;
  width: 200px; /* Set a fixed width */
  height: 200px; /* Set a fixed height */
  overflow: hidden; /* Ensures nothing goes outside the bounds of the card */
}

.member-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* This will cover the whole area, potentially cropping the image */
  display: block; /* Removes bottom space/margin that inline elements have */
}

.member-name {
  position: absolute; /* Positions the name inside the card */
  bottom: 0; /* At the bottom of the card */
  left: 0;
  width: 100%; /* Full width */
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  color: white; /* White text */
  text-align: center; /* Center the text */
  padding: 10px 0; /* Add some padding */
}

/* Adjust button styling as needed */
button {
  padding: 10px 15px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: var(--accent-color);
}